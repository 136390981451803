<template>
  <div
    class="relative mx-auto h-[23.125rem] overflow-hidden bg-s-900 text-white md:block md:h-[32.875rem]"
  >
    <!-- Background -->
    <picture>
      <source media="(min-width: 768px)" :srcset="desktopBackground" />
      <img
        :src="mobileBackground"
        class="relative min-h-full min-w-full object-cover 5xl:w-full"
        width="100%"
        height="100%"
        loading="lazy"
        decoding="auto"
        alt=" Earth from space"
      />
    </picture>

    <!-- Overlay -->
    <div class="absolute left-0 top-0 h-full w-full bg-black/20" />

    <!-- Content -->
    <div
      class="absolute bottom-0 left-0 right-0 top-0 mx-10 flex items-center justify-center"
    >
      <div class="p-3 backdrop-blur-[1px] md:p-0 md:backdrop-blur-none">
        <p
          class="mt-3 hidden max-w-[55.625rem] text-center text-h5 md:block md:text-h1"
        >
          Prices, Data & Intelligence for platform technologies driving the
          Energy Transition
        </p>

        <div class="items-center gap-x-6 md:mt-6 md:flex">
          <!-- Award + Anniversary: Badges -->
          <div class="flex w-fit shrink-0 gap-x-3 xl:gap-x-6">
            <img
              :src="AWARD_BADGE_URL"
              alt=" King's Award emblem"
              class="h-[8.125rem] w-[8.125rem]"
              loading="lazy"
              decoding="auto"
            />
            <img
              :src="ANNIVERSARY_BADGE_URL"
              alt=" Benchmark 10 year anniversary logo"
              class="h-[8.125rem] w-[8.125rem]"
              loading="lazy"
              decoding="auto"
            />
          </div>

          <!-- Award + Anniversary: Description -->
          <p
            class="mt-3 w-full max-w-[17.375rem] grow text-center text-h5 md:max-w-[36.625rem] md:text-left md:text-h3"
          >
            Received The King’s Award for Enterprise from King Charles III on
            the recommendation of the Prime Minister
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ANNIVERSARY_BADGE_URL,
  AWARD_BADGE_URL,
} from '~/constants/common-assets'

export default {
  data() {
    return {
      ANNIVERSARY_BADGE_URL,
      AWARD_BADGE_URL,
      /*
        There is an issue where the srcset value gets split by comma,
        if you set the it directly.

        That's why we are passing the image URL as a prop.
      */
      desktopBackground:
        'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,g_auto,w_1920,h_800,f_auto/v1678804229/assets/home/VideoBannerHome_npgzv9.jpg',
      mobileBackground:
        'https://res.cloudinary.com/benchmarkminerals/image/upload/f_auto,q_auto,w_1200,h_800,c_fill,so_-40/v1678804229/assets/home/VideoBannerHome_npgzv9.jpg',
    }
  },
}
</script>
