<template>
  <div class="flex h-full w-full items-center justify-center">
    <div class="grow">
      <div data-testid="hero" class="relative h-full">
        <HomeHeroSection />
        <AnnouncementsBar :latest-announcement="latestAnnouncement" />
        <CarouselSection
          :event="event"
          :articles="articles"
          :loading="loading"
        />
      </div>
      <div
        class="max-w-content-container px-small mx-auto mb-6 md:mb-0 lg:px-14 2xl:px-0"
      >
        <HomeFeatures />
      </div>

      <HomeEarthBanner class="my-6 md:mb-10 md:mt-7" />

      <h2
        class="max-w-content-container px-small text-surface-em-high mx-auto mb-3 text-2xl font-bold leading-8 lg:px-14 lg:text-3xl lg:leading-9 2xl:px-0"
      >
        Lithium ion batteries: Benchmark’s unique supply chain approach
      </h2>
      <div class="px-small md:px-0">
        <HomeSupplyChain />
      </div>
      <div
        data-testid="our-divisions-section"
        class="max-w-content-container px-small py-big lg:px-big mx-auto 2xl:px-0"
      >
        <h3 class="mb-4 text-xl font-bold md:text-2xl lg:text-3xl">
          Our Divisions
        </h3>
        <OurDivisionsSection />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  getEventsSinceLastYear,
  getUpcomingEvents,
  processEvents,
} from '@/utils/events'
import HomeHeroSection from '~/components/home/HeroSection.vue'
import HomeFeatures from '~/components/home/Features.vue'
import HomeEarthBanner from '~/components/home/EarthBanner.vue'
import HomeSupplyChain from '~/components/home/SupplyChain.vue'
import OurDivisionsSection from '~/components/OurDivisionsSection.vue'
import { TITLE } from '~/utils/constants'
import latestAnnouncementQuery from '~/queries/latest-announcement.gql'
import { logError } from '~/utils/log-error'
import CarouselSection from '~/components/home/CarouselSection.vue'
import AnnouncementsBar from '~/components/home/AnnouncementsBar.vue'

const { $graphqlClient, $bmAuth, $store } = useNuxtApp()

const getArticles = async () => {
  const ITEMS_ARTICLES = 3
  const response = await $graphqlClient.getMembershipArticles({
    first: ITEMS_ARTICLES,
    showOnlyFeaturedPosts: true,
  })

  return response.data.memberships?.nodes || []
}

const getEvents = async () => {
  const response = await $graphqlClient.getEventsWithDateQuery()
  const eventsRaw = response.data.events.nodes.filter(
    (event) => event.isFeatured,
  )
  const events = processEvents(eventsRaw)
  const eventsSinceLastYear = getEventsSinceLastYear(events)
  const upcomingEvents = getUpcomingEvents(eventsSinceLastYear)

  if (upcomingEvents?.length === 0) return null

  return upcomingEvents[0]
}

const getLatestAnnouncement = async () => {
  try {
    const { data } = await $bmAuth.getPublicGraphqlClient().query({
      query: latestAnnouncementQuery,
    } as any)
    return data
  } catch (error) {
    logError(error)
  }
}

const { data, status } = useAsyncData(async () => {
  $store.commit('modal/setInitialFields', {})
  const [articlesResponse, eventsResponse, latestAnnouncementResponse] =
    await Promise.all([getArticles(), getEvents(), getLatestAnnouncement()])
  return {
    articles: articlesResponse,
    event: eventsResponse,
    latestAnnouncement: latestAnnouncementResponse,
  }
})

const loading = computed(() => status.value !== 'success')
const articles = computed(() => data.value?.articles)
const event = computed(() => data.value?.event)
const latestAnnouncement = computed(() => data.value?.latestAnnouncement)

const { getSeoInfo } = useSeo()
const seo = getSeoInfo({ title: TITLE })

useHead(seo)
</script>
