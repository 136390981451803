<template>
  <div
    class="relative mx-auto flex h-[23.375rem] overflow-hidden transition-all duration-700 lg:max-h-[calc(100vh_-_260px)] lg:min-h-[32rem] xl:max-h-[calc(100vh_-_200px)]"
  >
    <div id="bgImage" class="relative grow">
      <picture>
        <source media="(max-width:768px)" :srcset="image.mobile" />
        <source media="(min-width:769px)" :srcset="image.desktop" />
        <img
          :src="image.default"
          class="h-full w-full object-cover lg:opacity-100"
          alt="Battery Home Hero"
          aria-hidden="true"
          width="100%"
          height="100%"
        />
      </picture>

      <!-- Overlay (Mobile) -->
      <div
        class="absolute left-0 top-0 h-full w-full bg-black/50 md:bg-transparent"
      />

      <!-- Overlay (Desktop) -->
      <div
        class="absolute left-0 top-0 hidden h-full w-full bg-[linear-gradient(90deg,rgba(0,0,0,0.8834384095435049)_33%,rgba(0,0,0,0.5024860285911239)_81%,rgba(0,0,0,0)_100%)] opacity-45 bg-blend-soft-light md:block"
      />

      <div
        class="max-w-content-container absolute bottom-0 left-0 right-0 top-0 mx-auto"
      >
        <div class="relative h-full w-full lg:pr-[18.5rem]">
          <div
            class="flex h-full w-full flex-col justify-end px-[1.125rem] pb-7 text-white md:max-w-[860px] md:px-8 md:pt-0 lg:px-big lg:pb-20 2xl:pl-0 5xl:max-w-[1000px] 5xl:pr-0"
          >
            <h2
              class="w-[90%] text-4xl font-bold leading-10 md:w-auto md:text-5xl"
            >
              Enabling the Energy Transition
            </h2>
            <p class="mt-6 max-w-[41.875rem] text-h6 md:mt-2">
              Benchmark’s prices and data empowers clients: from critical
              mineral contracts to EV battery supply chain investments and
              government policy
            </p>

            <div class="mt-6 hidden space-x-6 md:mb-12 md:flex lg:mb-0">
              <CommonButton variant="primary" @click="$modal.open('contact')">
                Contact Us
              </CommonButton>
            </div>
          </div>

          <!-- Award + Anniversary Badges -->
          <div
            class="absolute right-3 top-3 flex gap-x-3 lg:bottom-20 lg:right-[3.75rem] lg:top-auto xl:gap-x-6"
          >
            <img
              :src="AWARD_BADGE_URL"
              alt=" King's Award emblem"
              class="h-20 w-20 lg:h-[8.125rem] lg:w-[8.125rem]"
            />
            <img
              :src="ANNIVERSARY_BADGE_URL"
              alt=" Benchmark 10 year anniversary logo"
              class="h-20 w-20 lg:h-[8.125rem] lg:w-[8.125rem]"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonButton from '~/components/common/Button.vue'
import ScreenSizeMixin from '~/mixins/ScreenSizeMixin'
import ViewHeightMobile from '~/mixins/ViewHeightMobile.js'

import {
  ANNIVERSARY_BADGE_URL,
  AWARD_BADGE_URL,
} from '~/constants/common-assets'

export default {
  components: { CommonButton },
  mixins: [ScreenSizeMixin, ViewHeightMobile],
  data() {
    return {
      image: {
        desktop:
          'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,g_auto,w_3000,f_auto/v1685978797/assets/home/HomeHeroSuperWide_lxyvhm.png?_i=AA',
        mobile:
          'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,g_auto,w_1200,f_auto/v1680716978/assets/home/battery-home-hero_trcfsl.jpg?_i=AA',
        default:
          'https://res.cloudinary.com/benchmarkminerals/image/upload/c_fill,q_auto,g_auto,w_1200,f_auto/v1680716978/assets/home/battery-home-hero_trcfsl.jpg?_i=AA',
      },
      ANNIVERSARY_BADGE_URL,
      AWARD_BADGE_URL,
    }
  },
}
</script>
